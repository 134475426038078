export function getUrlObject(url) {
  if (!url || url.startsWith("/") || url.startsWith("?")) {
    return null;
  }

  try {
    const urlObj = new URL(url);
    return urlObj;
  } catch (e) {
    console.warn(`can't convert "${url}" to URL object`);
    return null;
  }
}

const INTERNAL_ORIGINS = ["https://www.obama.org", "http://www.obama.org", "https://obama.org"];

export function isExternalUrl(url, currentOrigin) {
  if (!url || url.startsWith("/") || url.startsWith("?")) {
    return false;
  }

  const urlObj = getUrlObject(url);
  if (urlObj) {
    const targetOrigin = urlObj.origin;
    if (INTERNAL_ORIGINS.includes(targetOrigin)) {
      return false;
    }
    return targetOrigin !== currentOrigin;
  }
  // Default to the link being external if we can't figure out the origin
  return true;
}

/*
  correctedUrl 
  a) makes internal full links relative (So www.obama.org/about becomes /about, so sveltekit will render page on static build)
  b) adds trailing slash if not 
*/
export function correctedUrl(url) {
  let isExternal = isExternalUrl(url);
  if (!isExternal) {
    const urlObj = getUrlObject(url);
    if (urlObj) {
      url = urlObj.pathname;
    }
  }
  return isMissingTrailingSlash(url) ? `${url}/` : url;
}

/**
 * naïve helper to add the origin to url if internal
 *
 * @param {*} url
 * @param {*} currentOrigin
 * @returns
 */
export function getFullUrl(url, currentOrigin) {
  if (!url) {
    return "";
  }

  if (url.startsWith("/") || url.startsWith("?")) {
    return `${currentOrigin}${url?.startsWith("/") ? "" : "/"}${url}`;
  }

  return url;
}

export function isNotStaticFile(url) {
  const types = [".pdf", ".mp4", ".html", ".jpg", ".jpeg", ".png", ".csv", ".zip"];
  return !types.some((type) => url.includes(type));
}

export function isRootRelative(url) {
  return url.startsWith("/");
}

export function hasNoTrailingSlash(url) {
  return !url.endsWith("/") && !url.match(/(\/)?#.*$/) && !url.match(/(\/)?\?.*$/);
}

export function isMissingTrailingSlash(url) {
  return !!url && isNotStaticFile(url) && isRootRelative(url) && hasNoTrailingSlash(url);
}
