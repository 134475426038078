export const getTotalNumberOfSelectedFilters = (activeFilters = []) => {
  return activeFilters.reduce((accumulator, currentFilterGroup) => {
    currentFilterGroup.bodyProps.options.forEach((option) => {
      if (option.isChecked === true) {
        accumulator++;
      }
    });
    return accumulator;
  }, 0);
};

export const filterOutInactiveFilterGroups = (filterGroups) => {
  return filterGroups.filter((filterGroup) => {
    return filterGroup.bodyProps.options.some((option) => {
      return option.isChecked;
    });
  });
};

/**
 * transform applied filter groups to active filters
 *
 * @param {*} selectedFilterGroups
 * @returns
 */
export const flattenAppliedFilterGroups = (selectedFilterGroups) =>
  selectedFilterGroups.reduce((selectedOptions, currentFilterGroup) => {
    currentFilterGroup.bodyProps.options.forEach((option) => {
      if (option.isChecked) {
        selectedOptions.push(option);
      }
    });
    return selectedOptions;
  }, []);
