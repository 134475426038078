import { parseHTML } from "linkedom";

/**
 * @param { String } string
 * @return HTMLDocument
 */
export function createDocumentFromString(string) {
  const parser = parseHTML(string);
  return parser.document;
}

/**
 * creates an array of parent elements sorted from closest parent of node to furthest parent of node.
 * inclusive of the element matching `condition`
 *
 * @param { HTMLElement } node
 * @param { Function } condition
 * @returns { HTMLElement[] } array of parent elements
 */

export function parentsThrough(node, condition) {
  const breakOnCondition = condition && typeof condition == "function";

  let parent = node.parentNode;
  // Escape hatch for development mode

  const parents = [];
  while (parent && parent.tagName !== "BODY") {
    parents.push(parent);

    if (breakOnCondition && condition(parent)) {
      return parents;
    }
    if (parent.parentNode?.id === "svelte") {
      parent = parent.parentNode.parentNode;
    }
    parent = parent.parentNode;
  }
  return parents;
}

/**
 * receives a string and strips its non alphanumeric characters
 *
 * @param { String } text
 * @param { String } id
 * @return a lowercase alphanumeric string
 */
export function textToId(text) {
  const spaces = new RegExp(/\s/g);
  const anythingButSpacesAndLetters = /[^\sA-Za-z0-9]/g;
  const punctuationToStrip = new RegExp(anythingButSpacesAndLetters);
  const id = text?.toLowerCase().replace(punctuationToStrip, "").replace(spaces, "-");
  return id;
}

export function hasData(onNode, key) {
  if (!onNode) {
    return false;
  }
  if (!onNode.dataset) {
    return false;
  }
  return onNode.dataset[key] !== undefined;
}

export function copyElement({ element, elementType, textContent, id, rootNode = document }) {
  if (!elementType) {
    console.error("no elementType provided to copyElement");
    return;
  }
  const newElement = rootNode.createElement(elementType);
  [...element.attributes].forEach(({ name, value }) => {
    newElement.setAttribute(name, value);
  });
  newElement.textContent = textContent;
  newElement.id = id;

  return newElement;
}

export function clickOutside(element, callbackFunction) {
  function onClick(event) {
    if (!element.contains(event.target)) {
      callbackFunction(event);
    }
  }

  document.body.addEventListener("click", onClick);

  return {
    update(newCallbackFunction) {
      callbackFunction = newCallbackFunction;
    },
    destroy() {
      document.body.removeEventListener("click", onClick);
    }
  };
}

export function elementIsFullyInViewport(element) {
  const elementRect = element.getBoundingClientRect();
  const headerOffset =
    document.documentElement.style.getPropertyValue("--scroll-header-padding") ?? 0;
  return (
    elementRect.top >= parseInt(headerOffset) &&
    elementRect.left >= 0 &&
    elementRect.bottom <= window.innerHeight &&
    elementRect.right <= window.innerWidth
  );
}
