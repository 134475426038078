export const IMAGE_VARIANT_MAP = {
  "1:1": "square",
  "3:2": "landscape",
  "3:4": "portrait"
};
export const ASPECT_RATIO_CLASSES = {
  RATIO_1_1: "aspect-1/1",
  RATIO_4_3: "aspect-4/3",
  RATIO_3_2: "aspect-3/2",
  RATIO_3_4: "aspect-3/4",
  RATIO_AUTO: "aspect-[auto]"
};

export const STORY_CARD_ASPECT_RATIO_CLASSES = {
  RATIO_1_1: "aspect-1/1",
  RATIO_3_2: "aspect-3/2",
  RATIO_3_4: "aspect-3/4"
};

// A sensible array of widths for srcset.
export const SRCSET_WIDTHS = [180, 360, 420, 720, 850, 1250, 1670, 3200];
